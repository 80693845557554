'use client';

import type {ComponentPropsWithRef} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import type {EmblaCarouselType} from 'embla-carousel';

import {ArrowLeftIcon} from '@/modules/foundation/components/icons/arrow-left-icon';
import {ArrowRightIcon} from '@/modules/foundation/components/icons/arrow-right-icon';

type UsePrevNextButtonsType = {
	prevBtnDisabled: boolean;
	nextBtnDisabled: boolean;
	onPrevButtonClick: () => void;
	onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
	emblaApi: EmblaCarouselType | undefined,
): UsePrevNextButtonsType => {
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

	const onPrevButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollPrev();
	}, [emblaApi]);

	const onNextButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollNext();
	}, [emblaApi]);

	const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
		setPrevBtnDisabled(!emblaApi.canScrollPrev());
		setNextBtnDisabled(!emblaApi.canScrollNext());
	}, []);

	useEffect(() => {
		if (!emblaApi) return;

		onSelect(emblaApi);
		emblaApi.on('reInit', onSelect).on('select', onSelect);
	}, [emblaApi, onSelect]);

	return {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick,
	};
};

type PropType = ComponentPropsWithRef<'button'>;

export const PrevButton: React.FC<PropType> = (props) => {
	const {children, ...restProps} = props;

	return (
		<button
			className={clsx(
				'py-2',
				'border-b',
				'border-secondary-dark',
				'disabled:opacity-50',
				'hover:text-secondary-dark',
				'disabled:hover:text-primary',
				'transition-colors',
			)}
			type="button"
			{...restProps}
		>
			<span className="sr-only">forrige prosjekt</span>
			<ArrowLeftIcon width={15} aria-hidden />
			{children}
		</button>
	);
};

export const NextButton: React.FC<PropType> = (props) => {
	const {children, ...restProps} = props;

	return (
		<button
			className={clsx(
				'py-2',
				'border-b',
				'border-secondary-dark',
				'disabled:opacity-50',
				'hover:text-secondary-dark',
				'disabled:hover:text-primary',
				'transition-colors',
			)}
			type="button"
			{...restProps}
		>
			<span className="sr-only">neste prosjekt</span>
			<ArrowRightIcon width={15} aria-hidden />
			{children}
		</button>
	);
};
