import type {PropsWithChildren} from 'react';
import clsx from 'clsx';

import {Container, type ContainerSpacing} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren & {
	heading: string;
	spacingBottom?: ContainerSpacing;
	spacingTop?: ContainerSpacing;
};

export const textFeaturedContentPortableTextOverrides: PortableTextOverrides = {};

export const TextFeatured = ({heading, children, spacingBottom, spacingTop}: Props) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			className={clsx('bg-primary', 'text-beige-100')}
			hasBackground
		>
			<section
				className={clsx(
					'flex',
					'flex-col',
					'min-[1480px]:flex-row',
					'justify-between',
					'gap-8',
				)}
			>
				<Heading
					level={2}
					className={clsx('text-3xl', 'md:text-6xl', 'text-balance', 'text-secondary')}
				>
					{heading}
				</Heading>
				{children && <div className={clsx('md:max-w-[910px]', 'md:pt-6')}>{children}</div>}
			</section>
		</Container>
	);
};
