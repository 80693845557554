import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import {internalLinkToHref} from '@/modules/foundation/sanity/link/query';

import type {ProjectDetails} from '../shared/types';

import {DOCUMENT_TYPE_PROJECT} from './constants';
import type {ProjectSchema} from './schema';

export const PROJECT_IN_CARD_FRAGMENT = groq`
	_id,
	title,
	slug,
	image,
	shortLead
`;

export type ProjectInCardFragment = Pick<
	ProjectSchema,
	'_id' | 'title' | 'slug' | 'shortLead' | 'image'
>;

export function projectInCardFragmentToProjectDetails(
	project: ProjectInCardFragment,
): ProjectDetails {
	return {
		title: project.title ?? '',
		shortLead: project.shortLead ?? '',
		href: internalLinkToHref(DOCUMENT_TYPE_PROJECT, project.slug?.current),
		imageWeb: project?.image?.asset
			? imageWebFromImageWebSchema(project.image, 750 / 500)
			: undefined,
	};
}
