import type {PropsWithChildren} from 'react';
import clsx from 'clsx';

import {Link} from '@/modules/foundation/components/link';

type Props = PropsWithChildren & {
	href?: string;
	targetBlank?: boolean;
};

export const PortableLink = ({children, href, targetBlank}: Props) => {
	return (
		<Link
			href={href}
			targetBlank={targetBlank}
			className={clsx('hover:text-secondary-dark', 'hover:underline', 'transition-colors')}
		>
			{children}
		</Link>
	);
};
