/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import type {FC} from 'react';
import type {PortableTextComponentProps, PortableTextMarkComponentProps} from '@portabletext/react';
import {PortableText as PortableTextReact} from '@portabletext/react';
import type {PortableTextBlock} from '@portabletext/types';
import type {SetOptional} from 'type-fest';

import {BulletList} from '@/modules/foundation/components/portable-text/bullet-list';
import {Heading2, Heading3} from '@/modules/foundation/components/portable-text/headings';
import {Normal} from '@/modules/foundation/components/portable-text/normal';
import {NumberList} from '@/modules/foundation/components/portable-text/number-list';
import {PortableImage} from '@/modules/foundation/components/portable-text/portable-image';
import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import type {ImageWebSchema} from '@/modules/foundation/sanity/image/schema';
import type {LinkFragment} from '@/modules/foundation/sanity/link/query';
import {cn} from '@/modules/foundation/shared/tailwind';

import {Paragraph} from '../../components/typography/paragraph';

import {CallToActionLineContainer} from './call-to-action-line-container/CallToActionLine';
import type {CallToActionLineFragment} from './call-to-action-line-container/query';
import {PortableLinkContainer} from './portable-link-container';

/**
 * Override class names for portable text components
 */
export type PortableTextOverrides = {
	p?: string;
	h2?: string;
	h3?: string;
	ul?: string;
	ol?: string;
	callToActionLine?: string;
	largeText?: string;
};

interface Props {
	blocks: SetOptional<PortableTextBlock, 'children'>[];
	overrideClassNames?: PortableTextOverrides;
	theme?: 'light' | 'dark';
}

export const PortableText: FC<Props> = ({blocks, overrideClassNames, theme = 'light'}) => {
	return (
		<PortableTextReact
			value={blocks}
			components={{
				block: {
					normal: ({children}) => (
						<Normal className={cn([overrideClassNames?.p])}>{children}</Normal>
					),
					h2: ({children}) => (
						<Heading2 className={cn([overrideClassNames?.h2])}>{children}</Heading2>
					),
					h3: ({children}) => (
						<Heading3 className={cn([overrideClassNames?.h3])}>{children}</Heading3>
					),
					largeText: ({children}) => (
						<Paragraph
							className={cn([
								'text-lg',
								'md:text-2xl',
								'mb-10',
								'md:mb-16',
								'last:mb-0',
								overrideClassNames?.largeText,
							])}
						>
							{children}
						</Paragraph>
					),
				},
				marks: {
					link: ({value, children}: PortableTextMarkComponentProps<LinkFragment>) => {
						if (!value) return null;
						return (
							<PortableLinkContainer value={value}>{children}</PortableLinkContainer>
						);
					},
					linkAnnotation: () => null,
				},
				list: {
					bullet: ({children}) => (
						<BulletList className={overrideClassNames?.ul}>{children}</BulletList>
					),
					number: ({children}) => (
						<NumberList className={overrideClassNames?.ol}>{children}</NumberList>
					),
				},
				types: {
					callToActionLine: ({
						value,
					}: PortableTextComponentProps<CallToActionLineFragment>) => (
						<CallToActionLineContainer
							value={value}
							className={overrideClassNames?.callToActionLine}
							color={theme === 'light' ? 'dark' : 'light'}
						/>
					),
					image: ({value}: PortableTextComponentProps<ImageWebSchema | null>) => {
						if (!value?.asset) {
							return null;
						}
						return <PortableImage image={imageWebFromImageWebSchema(value)} />;
					},
				},
			}}
		/>
	);
};
