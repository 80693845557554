import type {ReactNode} from 'react';

import {cn} from '@/modules/foundation/shared/tailwind';

type Props = {
	children: ReactNode;
	className?: string;
};

export const Heading2 = ({children, className}: Props) => {
	return (
		<h2 className={cn(['text-xl', 'md:text-2xl', 'font-bold', 'mb-3', 'md:mb-4', className])}>
			{children}
		</h2>
	);
};

export const Heading3 = ({children, className}: Props) => {
	return (
		<h3 className={cn(['text-lg', 'md:text-xl', 'font-bold', 'mb-3', 'md:mb-4', className])}>
			{children}
		</h3>
	);
};
