import PersonList from '../../components/person-list/PersonList';

import {personListFromPersonListSelectionSection, type PersonListSelectionFragment} from './query';

type Props = {
	data: PersonListSelectionFragment;
};

export const PersonListSelectionContainer = ({data}: Props) => {
	return (
		<PersonList
			people={personListFromPersonListSelectionSection(data)}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
