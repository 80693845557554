'use client';

import {useEffect, useLayoutEffect, useState} from 'react';

export const useViewportWidth = () => {
	const [viewportWidth, setViewportWidth] = useState(0);

	useLayoutEffect(() => {
		setViewportWidth(window.innerWidth);
	}, []);

	useEffect(() => {
		const handleResize = () => setViewportWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return viewportWidth;
};
