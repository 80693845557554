import type {LinkFragment} from '@/modules/foundation/sanity/link/query';
import {LINK_FRAGMENT} from '@/modules/foundation/sanity/link/query';
import {PORTABLE_TEXT_FRAGMENT} from '@/modules/foundation/sanity/portable-text/query';

import type {FeaturedLinkCardSchema} from './schema';

export const FEATURED_LINK_CARD_FRAGMENT = `//groq
	...,
	descriptionDesktop[]{
		${PORTABLE_TEXT_FRAGMENT}
	},
	link{
		${LINK_FRAGMENT}
	}
`;

export type FeaturedLinkCardFragment = Omit<FeaturedLinkCardSchema, 'link'> & {
	link?: LinkFragment;
};
