import {CustomerTestimonialSelection} from '../../components/customer-testimonial-selection';

import {
	customerTestimonialDetailsFromCustomerTestimonialSelectionFragment,
	type CustomerTestimonialSelectionFragment,
} from './query';

type Props = {
	data: CustomerTestimonialSelectionFragment;
};

export const CustomerTestimonialSelectionContainer = ({data}: Props) => {
	const details = customerTestimonialDetailsFromCustomerTestimonialSelectionFragment(data);
	return <CustomerTestimonialSelection {...details} />;
};
