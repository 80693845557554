import {groq} from 'next-sanity';

import {BLOCK_TYPE_TEXT} from '@/modules/page/sanity/content-blocks/text-container/constants';
import type {TextSectionFragmentType} from '@/modules/page/sanity/content-blocks/text-container/query';
import {TEXT_SECTION_FRAGMENT} from '@/modules/page/sanity/content-blocks/text-container/query';
import {BLOCK_TYPE_PERSON_LIST_SELECTION} from '@/modules/person/sanity/person-list-selection-container/constants';
import type {PersonListSelectionFragment} from '@/modules/person/sanity/person-list-selection-container/query';
import {PERSON_LIST_SELECTION_FRAGMENT} from '@/modules/person/sanity/person-list-selection-container/query';
import {BLOCK_TYPE_PROJECT_LIST_ALL_CAROUSEL} from '@/modules/vaag/project/sanity/project-list-all-carousel-container/constants';
import type {ProjectListAllCarouselFragment} from '@/modules/vaag/project/sanity/project-list-all-carousel-container/query';
import {PROJECT_LIST_ALL_CAROUSEL_FRAGMENT} from '@/modules/vaag/project/sanity/project-list-all-carousel-container/query';
import {BLOCK_TYPE_PROJECT_LIST_ALL} from '@/modules/vaag/project/sanity/project-list-all-container/constants';
import type {ProjectListAllFragment} from '@/modules/vaag/project/sanity/project-list-all-container/query';
import {PROJECT_LIST_ALL_FRAGMENT} from '@/modules/vaag/project/sanity/project-list-all-container/query';
import {BLOCK_TYPE_PROJECT_LIST_SELECTION_CAROUSEL} from '@/modules/vaag/project/sanity/project-list-selection-carousel-container/constants';
import type {ProjectListSelectionCarouselFragment} from '@/modules/vaag/project/sanity/project-list-selection-carousel-container/query';
import {PROJECT_LIST_SELECTION_CAROUSEL_FRAGMENT} from '@/modules/vaag/project/sanity/project-list-selection-carousel-container/query';
import {BLOCK_TYPE_PROJECT_LIST_SELECTION} from '@/modules/vaag/project/sanity/project-list-selection-container/constants';
import {
	PROJECT_LIST_SELECTION_FRAGMENT,
	type ProjectListSelectionFragment,
} from '@/modules/vaag/project/sanity/project-list-selection-container/query';
import {BLOCK_TYPE_CUSTOMER_TESTIMONIAL_SELECTION} from '@/modules/vaag/sanity/customer-testimonial-selection/constants';
import {
	CUSTOMER_TESTIMONIAL_SELECTION_FRAGMENT,
	type CustomerTestimonialSelectionFragment,
} from '@/modules/vaag/sanity/customer-testimonial-selection/query';
import {BLOCK_TYPE_FEATURED_LINK_CARD} from '@/modules/vaag/sanity/featured-link-card/constants';
import type {FeaturedLinkCardFragment} from '@/modules/vaag/sanity/featured-link-card/query';
import {FEATURED_LINK_CARD_FRAGMENT} from '@/modules/vaag/sanity/featured-link-card/query';
import {BLOCK_TYPE_IMAGE} from '@/modules/vaag/sanity/image/constants';
import type {ImageBlockFragment} from '@/modules/vaag/sanity/image/query';
import {IMAGE_BLOCK_FRAGMENT} from '@/modules/vaag/sanity/image/query';
import {BLOCK_TYPE_IMAGE_EXPANDING} from '@/modules/vaag/sanity/image-expanding/constants';
import type {ImageExpandingFragment} from '@/modules/vaag/sanity/image-expanding/query';
import {IMAGE_EXPANDING_FRAGMENT} from '@/modules/vaag/sanity/image-expanding/query';
import {BLOCK_TYPE_IMAGES_COLUMNS} from '@/modules/vaag/sanity/images-columns/constants';
import type {ImagesColumnsFragment} from '@/modules/vaag/sanity/images-columns/query';
import {IMAGES_COLUMNS_FRAGMENT} from '@/modules/vaag/sanity/images-columns/query';
import {BLOCK_TYPE_LOGO_LIST_SELECTION} from '@/modules/vaag/sanity/logo-list-selection/constants';
import type {LogoListSelectionFragment} from '@/modules/vaag/sanity/logo-list-selection/query';
import {LOGO_LIST_SELECTION_FRAGMENT} from '@/modules/vaag/sanity/logo-list-selection/query';
import {BLOCK_TYPE_SERVICE_AREA_LIST_SELECTION} from '@/modules/vaag/sanity/service-area-list-selection/constants';
import {
	SERVICE_AREA_LIST_SELECTION_FRAGMENT,
	type ServiceAreaListSelectionFragment,
} from '@/modules/vaag/sanity/service-area-list-selection/query';
import {BLOCK_TYPE_TEXT_FEATURED} from '@/modules/vaag/sanity/text-featured/constants';
import type {TextFeaturedFragment} from '@/modules/vaag/sanity/text-featured/query';
import {BLOCK_TYPE_VACANCY_LIST_SELECTION} from '@/modules/vaag/sanity/vacancy-list-selection/constants';
import {
	VACANCY_LIST_SELECTION_FRAGMENT,
	type VacancyListSelectionFragment,
} from '@/modules/vaag/sanity/vacancy-list-selection/query';
import {BLOCK_TYPE_VIDEO} from '@/modules/vaag/sanity/video/constants';
import type {VideoFragment} from '@/modules/vaag/sanity/video/query';
import {VIDEO_FRAGMENT} from '@/modules/vaag/sanity/video/query';

import {TEXT_FEATURED_FRAGMENT} from './../../../vaag/sanity/text-featured/query';

export const CONTENT_BLOCK_FRAGMENT = groq`
	...,

	// Klart foundation
	_type == "${BLOCK_TYPE_TEXT}" => {
		${TEXT_SECTION_FRAGMENT}
	},

	// Project extension
	_type == "${BLOCK_TYPE_PROJECT_LIST_ALL}" => {
		${PROJECT_LIST_ALL_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_PROJECT_LIST_SELECTION}" => {
		${PROJECT_LIST_SELECTION_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_PROJECT_LIST_ALL_CAROUSEL}" => {
		${PROJECT_LIST_ALL_CAROUSEL_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_PROJECT_LIST_SELECTION_CAROUSEL}" => {
		${PROJECT_LIST_SELECTION_CAROUSEL_FRAGMENT}
	},

	// Person extension
	_type == "${BLOCK_TYPE_PERSON_LIST_SELECTION}" => {
		${PERSON_LIST_SELECTION_FRAGMENT}
	},

	// Customer
	_type == "${BLOCK_TYPE_CUSTOMER_TESTIMONIAL_SELECTION}" => {
		${CUSTOMER_TESTIMONIAL_SELECTION_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_LOGO_LIST_SELECTION}" => {
		${LOGO_LIST_SELECTION_FRAGMENT}
	},

	// Service
	_type == "${BLOCK_TYPE_SERVICE_AREA_LIST_SELECTION}" => {
		${SERVICE_AREA_LIST_SELECTION_FRAGMENT}
	},

	// Vacancy
	_type == "${BLOCK_TYPE_VACANCY_LIST_SELECTION}" => {
		${VACANCY_LIST_SELECTION_FRAGMENT}
	},

	// Video
	_type == "${BLOCK_TYPE_VIDEO}" => {
		${VIDEO_FRAGMENT}
	},

	// Image
	_type == "${BLOCK_TYPE_IMAGE}" => {
		${IMAGE_BLOCK_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_IMAGES_COLUMNS}" => {
		${IMAGES_COLUMNS_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_IMAGE_EXPANDING}" => {
		${IMAGE_EXPANDING_FRAGMENT}
	},

	// Text
	_type == "${BLOCK_TYPE_TEXT_FEATURED}" => {
		${TEXT_FEATURED_FRAGMENT}
	},

	// Featured link card
	_type == "${BLOCK_TYPE_FEATURED_LINK_CARD}" => {
		${FEATURED_LINK_CARD_FRAGMENT}
	}
`;

export type ContentBlockFragment =
	| TextSectionFragmentType
	| ProjectListAllFragment
	| ProjectListAllCarouselFragment
	| ProjectListSelectionCarouselFragment
	| PersonListSelectionFragment
	| CustomerTestimonialSelectionFragment
	| LogoListSelectionFragment
	| ServiceAreaListSelectionFragment
	| VacancyListSelectionFragment
	| VideoFragment
	| ImageBlockFragment
	| ImagesColumnsFragment
	| TextFeaturedFragment
	| ImageExpandingFragment
	| FeaturedLinkCardFragment
	| ProjectListSelectionFragment;
