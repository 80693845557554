import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ServiceAreaDetailsFragment} from '../service-area/query';
import {SERVICE_AREA_DETAILS_FRAGMENT} from '../service-area/query';
import type {Service, ServiceArea} from '../shared/types';

import type {BLOCK_TYPE_SERVICE_AREA_LIST_SELECTION} from './constants';
import type {ServiceAreaListSelectionSchema} from './schema';

export const SERVICE_AREA_LIST_SELECTION_FRAGMENT = groq`
	...,
	serviceAreas[]-> {
		${SERVICE_AREA_DETAILS_FRAGMENT}
	}
`;

export type ServiceAreaListSelectionFragment = Omit<
	ServiceAreaListSelectionSchema,
	'serviceAreas'
> & {
	_type: typeof BLOCK_TYPE_SERVICE_AREA_LIST_SELECTION;
	serviceAreas?: (ServiceAreaDetailsFragment | null)[];
};

export const serviceAreaListFromServiceAreaListSelectionSection = (
	section: ServiceAreaListSelectionFragment,
): KeyedArray<ServiceArea> => {
	const serviceAreas: KeyedArray<ServiceArea> = [];

	if (!section.serviceAreas) {
		return serviceAreas;
	}

	for (const serviceArea of section.serviceAreas) {
		if (serviceArea) {
			const services: KeyedArray<Service> = [];

			for (const service of serviceArea.serviceReferences ?? []) {
				services.push({
					_key: service._id,
					name: service.name ?? '',
				});
			}

			serviceAreas.push({
				_key: serviceArea._id,
				services: services,
				name: serviceArea.name ?? '',
			});
		}
	}

	return serviceAreas;
};
