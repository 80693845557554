import type {ReactNode} from 'react';
import clsx from 'clsx';

import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = {
	title: string;
	lead?: ReactNode;
	content?: ReactNode;
};

export const vacancyLeadPortableTextOverrides: PortableTextOverrides = {
	p: 'text-lg md:text-2xl',
};
export const vacancyContentPortableTextOverrides: PortableTextOverrides = {
	p: 'text-base md:text-lg',
};

export const VacancyCard = ({title, lead, content}: Props) => {
	return (
		<section
			className={clsx(
				'border-t',
				'border-secondary-dark',
				'pt-12',
				'md:pt-16',
				'flex',
				'flex-col',
				'min-[1100px]:flex-row',
				'justify-between',
				'gap-8',
			)}
		>
			<Heading
				level={2}
				size="medium"
				className={clsx('max-md:text-3xl', 'md:max-w-[483px]', 'font-normal', 'shrink-0')}
			>
				{title}
			</Heading>
			<div className={clsx('md:max-w-[910px]')}>
				{lead && <div className={clsx('mb-10', 'md:mb-14')}>{lead}</div>}
				{content && <div>{content}</div>}
			</div>
		</section>
	);
};
