import clsx from 'clsx';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';

type Props = {
	imageLeft?: ImageWeb;
	imageRight?: ImageWeb;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const ImagesColumns = ({imageLeft, imageRight, spacingBottom, spacingTop}: Props) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop} width="full">
			<div>
				<div className={clsx('grid', 'grid-cols-2', 'aspect-[8/5]')}>
					<div className={clsx('relative', 'bg-beige-200')}>
						{imageLeft && (
							<ImageFill
								src={imageLeft.image.src}
								alt={imageLeft.altText ?? ''}
								width={imageLeft.image.width}
								height={imageLeft.image.height}
								hotspot={imageLeft.image.hotspot}
								defaultSizes="50vw"
							/>
						)}
					</div>

					<div className={clsx('relative', 'bg-beige-300')}>
						{imageRight && (
							<ImageFill
								src={imageRight.image.src}
								alt={imageRight.altText ?? ''}
								width={imageRight.image.width}
								height={imageRight.image.height}
								hotspot={imageRight.image.hotspot}
								defaultSizes="50vw"
							/>
						)}
					</div>
				</div>
			</div>
		</Container>
	);
};
