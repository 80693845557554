'use client';

import {type FC, useCallback, useState} from 'react';
import clsx from 'clsx';

import {Button} from '@/modules/foundation/components/button';
import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {PlusIcon} from '@/modules/foundation/components/icons/plus-icon';
import type {KeyedArray} from '@/modules/foundation/shared/types';
import {ProjectGrid} from '@/modules/vaag/components/project-grid';

import type {ProjectDetails} from '../../shared/types';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	projects: KeyedArray<ProjectDetails>;
	limit?: number;
}

const ProjectListPaginated: FC<Props> = ({spacingBottom, spacingTop, projects, limit}) => {
	const [visiblePages, setVisiblePages] = useState(0);

	let visibleProjects = projects;

	if (limit) {
		visibleProjects = projects?.slice(0, limit);
		for (let i = 0; i < visiblePages; i++) {
			if (i + limit < projects.length) {
				visibleProjects.push(projects[i + limit]);
			}
		}
	}

	const loadMore = useCallback(() => {
		// loadMore will never be called if limit is 0 or undefined therefor the default limit does not matter
		setVisiblePages((prev) => prev + (limit ?? 2));
	}, [limit]);

	if (!visibleProjects) return null;

	const totalProjects = projects?.length;

	const hasMore = visibleProjects.length < totalProjects;

	const renderedProjects = visibleProjects;

	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<ProjectGrid projects={renderedProjects} />

			{hasMore && (
				<Button
					onClick={loadMore}
					className={clsx('justify-self-center', 'text-md', 'md:text-lg')}
				>
					Last inn flere prosjekter
					<PlusIcon aria-hidden height={14} width={14} />
				</Button>
			)}
		</Container>
	);
};

export default ProjectListPaginated;
