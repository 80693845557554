import type {FC} from 'react';
import React from 'react';

import ProjectListPaginated from '../../components/project-list-paginated';

import {type ProjectListAllFragment, projectListFromProjectListAllSection} from './query';

type Props = {
	data: ProjectListAllFragment;
};

export const ProjectListAllContainer: FC<Props> = ({data}) => {
	const projects = projectListFromProjectListAllSection(data);

	return (
		<ProjectListPaginated
			projects={projects}
			limit={data.limit}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
