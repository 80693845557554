import {PORTABLE_TEXT_FRAGMENT} from '@/modules/foundation/sanity/portable-text/query';

import type {VacancySchema} from './schema';

export const VACANCY_FRAGMENT = `//groq
	...,
	lead[] {
		${PORTABLE_TEXT_FRAGMENT}
	},
	content[] {
		${PORTABLE_TEXT_FRAGMENT}
	}
`;

export type VacancyFragment = VacancySchema;
