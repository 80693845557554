import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import type {KeyedArray} from '@/modules/foundation/shared/types';
import {ProjectGrid} from '@/modules/vaag/components/project-grid';

import type {ProjectDetails} from '../../shared/types';

type Props = {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	projects: KeyedArray<ProjectDetails>;
};

export const ProjectList = ({spacingBottom, spacingTop, projects}: Props) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<ProjectGrid projects={projects} />
		</Container>
	);
};
