import clsx from 'clsx';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import {ProjectCard} from '../../project/components/project-card';
import type {ProjectDetails} from '../../project/shared/types';

type Props = {
	projects: KeyedArray<ProjectDetails>;
};

export const ProjectGrid = ({projects}: Props) => {
	if (!projects || projects.length === 0) {
		return null;
	}

	return (
		<ul
			className={clsx(
				'grid',
				'grid-cols-1',
				'md:grid-cols-2',
				'gap-y-10',
				'md:gap-y-24',
				'md:gap-x-12',
				'mb-10',
				'md:mb-28',
			)}
		>
			{projects.map((project) => (
				<li key={project._key}>
					<ProjectCard project={project} />
				</li>
			))}
		</ul>
	);
};
