import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';

import {Image} from '../../components/image';

import type {ImageBlockFragment} from './query';

type Props = {
	data: ImageBlockFragment;
};

export const ImageBlockContainer = ({data}: Props) => {
	if (!data.imageDesktop?.asset) {
		return null;
	}

	return (
		// eslint-disable-next-line jsx-a11y/alt-text
		<Image
			imageDesktop={imageWebFromImageWebSchema(data.imageDesktop)}
			imageMobile={
				data.imageMobile ? imageWebFromImageWebSchema(data.imageMobile) : undefined
			}
			format={data.format}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
