import {type ReactNode} from 'react';
import clsx from 'clsx';

import type {ImageWeb} from '@/modules/foundation/components/image/types';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

import {Desktop} from './desktop';
import {MobileCard} from './mobile';

type Props = {
	heading: string;
	descriptionDesktop?: ReactNode;
	descriptionMobile?: string;
	imageDesktop?: ImageWeb;
	imageMobile?: ImageWeb;
	link?: {
		href: string;
		targetBlank: boolean;
	};
	theme?: 'light' | 'dark';
	textAlignment?: 'left' | 'right';
};

export const featuredLinkCardDescriptionDesktopPortableTextOverrides: PortableTextOverrides = {
	p: '!text-xl !font-display',
};

export const FeaturedLinkCard = ({
	heading,
	descriptionDesktop,
	descriptionMobile,
	imageDesktop,
	imageMobile,
	link,
	theme = 'dark',
	textAlignment = 'left',
}: Props) => {
	return (
		<div>
			<div className={clsx('hidden', 'md:block')}>
				<Desktop
					heading={heading}
					descriptionDesktop={descriptionDesktop}
					imageDesktop={imageDesktop}
					link={link}
					theme={theme}
					textAlignment={textAlignment}
				/>
			</div>

			<div className={clsx('md:hidden')}>
				<MobileCard
					heading={heading}
					description={descriptionMobile ?? ''}
					imageWeb={imageMobile}
					link={link}
				/>
			</div>
		</div>
	);
};
