import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';

import {ImagesColumns} from '../../components/images-columns';

import type {ImagesColumnsFragment} from './query';

type Props = {
	data: ImagesColumnsFragment;
};

export const ImagesColumnsContainer = ({data}: Props) => {
	return (
		// eslint-disable-next-line jsx-a11y/alt-text
		<ImagesColumns
			imageLeft={data.imageLeft ? imageWebFromImageWebSchema(data.imageLeft) : undefined}
			imageRight={data.imageRight ? imageWebFromImageWebSchema(data.imageRight) : undefined}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
