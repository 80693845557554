import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

import type {CustomerTestimonialDetails} from '../../sanity/shared/types';

type Props = CustomerTestimonialDetails;

export const CustomerTestimonialSelection = ({customer, name, quote, role}: Props) => {
	if (!quote) {
		return null;
	}
	return (
		<Container
			spacingTop="normal"
			spacingBottom="normal"
			width="text"
			className={clsx('text-center')}
			hasBackground
		>
			<div>
				<Paragraph asChild size="xxlarge" className="max-md:text-lg">
					<blockquote
						className={clsx('italic', 'font-[300]', 'mb-5', 'md:mb-7', 'text-balance')}
					>
						&laquo;{quote}&raquo;
					</blockquote>
				</Paragraph>
				{(name || role || customer) && (
					<Paragraph className={clsx('opacity-65', 'text-balance')}>
						{name && role && customer && `${name}, ${role} i ${customer}`}
						{name && role && !customer && `${name}, ${role}`}
						{name && !role && customer && `${name} i ${customer}`}
						{name && !role && !customer && name}
						{!name && role && customer && `${role} i ${customer}`}
						{!name && role && !customer && role}
						{!name && !role && customer && customer}
					</Paragraph>
				)}
			</div>
		</Container>
	);
};
