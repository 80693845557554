import {PortableText} from '@/modules/foundation/sanity/portable-text';

import {
	VacancyCard,
	vacancyContentPortableTextOverrides,
	vacancyLeadPortableTextOverrides,
} from '../../components/vacancy-card';
import {VacancyList} from '../../components/vacancy-list';

import {type VacancyListSelectionFragment} from './query';

type Props = {
	data: VacancyListSelectionFragment;
};

export const VacancyListSelectionContainer = ({data}: Props) => {
	return (
		<VacancyList
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		>
			{data.vacancies?.map((vacancy) => (
				<VacancyCard
					key={vacancy?._id}
					title={vacancy?.title ?? ''}
					lead={
						vacancy?.lead && (
							<PortableText
								blocks={vacancy.lead}
								overrideClassNames={vacancyLeadPortableTextOverrides}
							/>
						)
					}
					content={
						vacancy?.content && (
							<PortableText
								blocks={vacancy.content}
								overrideClassNames={vacancyContentPortableTextOverrides}
							/>
						)
					}
				/>
			))}
		</VacancyList>
	);
};
