import type {SVGProps} from 'react';

export const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 16 14"
		{...props}
	>
		<path
			fill="currentColor"
			d="M15.604 6.381a.875.875 0 0 1 0 1.238l-5.568 5.568a.875.875 0 1 1-1.238-1.237L13.748 7l-4.95-4.95A.875.875 0 1 1 10.036.813l5.568 5.568ZM0 6.125h14.985v1.75H0v-1.75Z"
		/>
	</svg>
);
