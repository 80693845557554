import type {FC} from 'react';
import React from 'react';

import ProjectListCarousel from '../../components/project-list-carousel';

import {
	projectListCarouselFromProjectListAllSection,
	type ProjectListSelectionCarouselFragment,
} from './query';

type Props = {
	data: ProjectListSelectionCarouselFragment;
};

export const ProjectListSelectionCarouselContainer: FC<Props> = ({data}) => {
	const projects = projectListCarouselFromProjectListAllSection(data);

	return (
		<ProjectListCarousel
			projects={projects}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			heading={data.heading}
		/>
	);
};
