import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerDetailsFragment} from '../customer/query';
import {CUSTOMER_DETAILS_FRAGMENT} from '../customer/query';
import type {CustomerDetails} from '../shared/types';

import type {SanityArray} from './../../../../sanity/lib/types';
import type {LogoListSelectionSchema} from './schema';

export const LOGO_LIST_SELECTION_FRAGMENT = `//groq
	...,
	customerReferences[]-> {
		${CUSTOMER_DETAILS_FRAGMENT},
	}
`;

export type LogoListSelectionFragment = Omit<LogoListSelectionSchema, 'customerReferences'> & {
	customerReferences?: SanityArray<CustomerDetailsFragment>;
};

export const logoListFromLogoListSelectionSection = (
	section: LogoListSelectionFragment,
): KeyedArray<CustomerDetails> => {
	const customers: KeyedArray<CustomerDetails> = [];

	if (!section.customerReferences?.length) {
		return customers;
	}

	for (const customer of section.customerReferences) {
		if (customer) {
			customers.push({
				_key: customer._id,
				name: customer.name,
				logo: customer.logo?.asset
					? imageWebFromImageWebSchema(customer.logo, 4 / 3)
					: undefined,
			});
		}
	}

	return customers;
};
