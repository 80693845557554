import type {ReactNode} from 'react';

import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {cn} from '@/modules/foundation/shared/tailwind';

type Props = {
	children: ReactNode;
	className?: string;
};

export const Normal = ({children, className}: Props) => {
	return (
		<Paragraph className={cn(['mb-4', 'last:mb-0', 'text-base', 'md:text-lg', className])}>
			{children}
		</Paragraph>
	);
};
