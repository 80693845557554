import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';

import {ImageExpanding} from '../../components/image-expanding';

import type {ImageExpandingFragment} from './query';

type Props = {
	data: ImageExpandingFragment;
};

export const ImageExpandingContainer = ({data}: Props) => {
	if (!data.imageDesktop?.asset) {
		return null;
	}

	return (
		<ImageExpanding
			imageDesktop={imageWebFromImageWebSchema(data.imageDesktop)}
			imageMobile={
				data.imageMobile ? imageWebFromImageWebSchema(data.imageMobile) : undefined
			}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
