'use client';

import {useRef} from 'react';
import clsx from 'clsx';
import {motion, useScroll, useTransform} from 'framer-motion';
import Image from 'next/image';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {useViewportWidth} from '@/modules/foundation/hooks/useViewportWidth';

type Props = {
	imageDesktop: ImageWeb;
	imageMobile?: ImageWeb;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const ImageExpanding = ({imageDesktop, imageMobile, spacingBottom, spacingTop}: Props) => {
	const ref = useRef<HTMLDivElement>(null);

	const viewportWidth = useViewportWidth();
	const maxInitialWidth = 1552 + viewportWidth * 0.1; // Initial max-width: 1552px + 10vw padding

	const {scrollYProgress} = useScroll({
		target: ref,
		offset: ['end end', 'start start'],
	});

	// Interpolate width from maxInitialWidth to viewportWidth (full screen)
	const width = useTransform(
		scrollYProgress,
		[1, 0], // start and end scroll values
		[maxInitialWidth, viewportWidth], // map to pixels
	);

	const height = useTransform(scrollYProgress, [1, 0], ['80dvh', '100dvh']);

	const padding = useTransform(scrollYProgress, [1, 0], ['5vw', '0vw']);

	const top = useTransform(scrollYProgress, [1, 0], ['10dvh', '0dvh']);

	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop} width="full">
			{/* Desktop version */}
			<div ref={ref} className={clsx('relative', 'h-[150vh]', 'hidden', 'md:block')}>
				<motion.div
					className={clsx('sticky', 'h-screen', 'overflow-hidden')}
					style={{
						top,
					}}
				>
					<motion.div
						className={clsx('relative', 'mx-auto')}
						style={{
							height,
							maxWidth: width,
							paddingLeft: padding,
							paddingRight: padding,
						}}
					>
						<div className={clsx('relative', 'h-full', 'w-full')}>
							<Image
								src={imageDesktop.image.src}
								alt={imageDesktop.altText ?? ''}
								style={{objectFit: 'cover'}}
								fill
								sizes="100vw"
							/>
						</div>
					</motion.div>
				</motion.div>
			</div>
			{/* Mobile version */}
			<div className={clsx('md:hidden')}>
				{imageMobile ? (
					<ImageBlock
						src={imageMobile.image.src}
						alt={imageMobile.altText ?? ''}
						height={imageMobile.image.height}
						width={imageMobile.image.width}
						defaultSizes="100vw"
					/>
				) : (
					<ImageBlock
						src={imageDesktop.image.src}
						alt={imageDesktop.altText ?? ''}
						height={imageDesktop.image.height}
						width={imageDesktop.image.width}
						defaultSizes="100vw"
					/>
				)}
			</div>
		</Container>
	);
};
