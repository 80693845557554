import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerDetails} from '../../sanity/shared/types';

type Props = {
	customers: KeyedArray<CustomerDetails>;
};

export const LogoGrid = ({customers}: Props) => {
	return (
		<Container spacingBottom="normal" spacingTop="normal">
			<div>
				<div
					className={clsx(
						'flex',
						'flex-wrap',
						'items-center',
						'justify-center',
						'gap-8',
						'sm:gap-x-12',
						'md:gap-x-16',
						'lg:gap-x-20',
						'xl:gap-x-24',
					)}
				>
					{customers.map((customer, idx) => (
						<div
							key={`${customer._key}-${idx}`}
							className={clsx(
								'flex',
								'flex-1',
								'min-w-[140px]',
								'justify-center',
								'items-center',
							)}
						>
							<div className={clsx('max-w-[170px]', 'flex-1')}>
								{customer.logo?.image.src && (
									<ImageBlock
										src={customer.logo?.image.src}
										height={customer.logo.image.height}
										width={customer.logo.image.width}
										alt={customer.name ?? ''}
										defaultSizes="170px"
									/>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</Container>
	);
};
