import {Video} from '../../components/video';

import type {VideoFragment} from './query';

type Props = {
	data: VideoFragment;
};

export const VideoContainer = ({data}: Props) => {
	if (!data.id || !data.title) {
		return null;
	}

	return (
		<Video
			video={{
				id: data.id,
				title: data.title,
			}}
			autoplay={data.autoplay ?? false}
			format={data.format ?? 'normal'}
			layout={data.layout}
		/>
	);
};
