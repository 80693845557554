import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import {linkFragmentToHref} from '@/modules/foundation/sanity/link/query';
import {PortableText} from '@/modules/foundation/sanity/portable-text';

import {
	FeaturedLinkCard,
	featuredLinkCardDescriptionDesktopPortableTextOverrides,
} from '../../components/featured-link-card';

import type {FeaturedLinkCardFragment} from './query';

type Props = {
	data: FeaturedLinkCardFragment;
};

export const FeaturedLinkCardContainer = ({data}: Props) => {
	const {
		descriptionDesktop,
		descriptionMobile,
		heading,
		imageDesktop,
		imageMobile,
		theme,
		textAlignment,
	} = data;

	if (!imageDesktop?.asset) {
		return null;
	}

	return (
		<FeaturedLinkCard
			heading={heading ?? ''}
			descriptionDesktop={
				descriptionDesktop && (
					<PortableText
						blocks={descriptionDesktop}
						overrideClassNames={featuredLinkCardDescriptionDesktopPortableTextOverrides}
					/>
				)
			}
			descriptionMobile={descriptionMobile}
			link={{
				href: data.link ? linkFragmentToHref(data.link) : '#',
				targetBlank: Boolean(data.link?.targetBlank),
			}}
			imageDesktop={imageDesktop && imageWebFromImageWebSchema(imageDesktop)}
			imageMobile={
				imageMobile
					? imageWebFromImageWebSchema(imageMobile, 750 / 500)
					: imageDesktop && imageWebFromImageWebSchema(imageDesktop, 750 / 500)
			}
			theme={theme}
			textAlignment={textAlignment}
		/>
	);
};
