import type {FC} from 'react';

import {TextContainer} from '@/modules/page/sanity/content-blocks/text-container';
import {PersonListSelectionContainer} from '@/modules/person/sanity/person-list-selection-container';
import {ProjectListAllCarouselContainer} from '@/modules/vaag/project/sanity/project-list-all-carousel-container';
import {ProjectListAllContainer} from '@/modules/vaag/project/sanity/project-list-all-container';
import {ProjectListSelectionCarouselContainer} from '@/modules/vaag/project/sanity/project-list-selection-carousel-container';
import {ProjectListSelectionContainer} from '@/modules/vaag/project/sanity/project-list-selection-container';
import {CustomerTestimonialSelectionContainer} from '@/modules/vaag/sanity/customer-testimonial-selection';
import {FeaturedLinkCardContainer} from '@/modules/vaag/sanity/featured-link-card';
import {ImageBlockContainer} from '@/modules/vaag/sanity/image';
import {ImageExpandingContainer} from '@/modules/vaag/sanity/image-expanding';
import {ImagesColumnsContainer} from '@/modules/vaag/sanity/images-columns';
import {LogoListSelectionContainer} from '@/modules/vaag/sanity/logo-list-selection';
import {ServiceAreaListSelectionContainer} from '@/modules/vaag/sanity/service-area-list-selection';
import {TextFeaturedContainer} from '@/modules/vaag/sanity/text-featured';
import {VacancyListSelectionContainer} from '@/modules/vaag/sanity/vacancy-list-selection';
import {VideoContainer} from '@/modules/vaag/sanity/video';
import type {SanityArray} from '@/sanity/lib/types';

import type {ContentBlockFragment} from './query';

interface Props {
	content: SanityArray<ContentBlockFragment>;
}

const ContentBlocks: FC<Props> = ({content}) => {
	const sections = content?.map((section) => {
		switch (section._type) {
			case 'textSection':
				return <TextContainer key={section._key} data={section} />;
			case 'projectListAll':
				return <ProjectListAllContainer key={section._key} data={section} />;
			case 'projectListSelection':
				return <ProjectListSelectionContainer key={section._key} data={section} />;
			case 'projectListAllCarousel':
				return <ProjectListAllCarouselContainer key={section._key} data={section} />;
			case 'projectListSelectionCarousel':
				return <ProjectListSelectionCarouselContainer key={section._key} data={section} />;
			case 'personListSelection':
				return <PersonListSelectionContainer key={section._key} data={section} />;
			case 'customerTestimonialSelection':
				return <CustomerTestimonialSelectionContainer key={section._key} data={section} />;
			case 'logoListSelection':
				return <LogoListSelectionContainer key={section._key} data={section} />;
			case 'serviceAreaListSelection':
				return <ServiceAreaListSelectionContainer key={section._key} data={section} />;
			case 'vacancyListSelection':
				return <VacancyListSelectionContainer key={section._key} data={section} />;
			case 'video':
				return <VideoContainer key={section._key} data={section} />;
			case 'imageBlock':
				return <ImageBlockContainer key={section._key} data={section} />;
			case 'imagesColumns':
				return <ImagesColumnsContainer key={section._key} data={section} />;
			case 'textFeatured':
				return <TextFeaturedContainer key={section._key} data={section} />;
			case 'imageExpanding':
				return <ImageExpandingContainer key={section._key} data={section} />;
			case 'featuredLinkCard':
				return <FeaturedLinkCardContainer key={section._key} data={section} />;
			default:
				return null;
		}
	});

	return <>{sections}</>;
};

export default ContentBlocks;
