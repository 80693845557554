import {groq} from 'next-sanity';

import type {VacancyFragment} from '../vacancy/query';
import {VACANCY_FRAGMENT} from '../vacancy/query';

import type {BLOCK_TYPE_VACANCY_LIST_SELECTION} from './constants';
import type {VacancyListSelectionSchema} from './schema';

export const VACANCY_LIST_SELECTION_FRAGMENT = groq`
	...,
	vacancies[]-> {
		${VACANCY_FRAGMENT}
	}
`;

export type VacancyListSelectionFragment = Omit<VacancyListSelectionSchema, 'vacancies'> & {
	_type: typeof BLOCK_TYPE_VACANCY_LIST_SELECTION;
	vacancies?: (VacancyFragment | null)[];
};
