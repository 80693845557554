import type {ServiceDetailsFragment} from '../service/query';
import type {ServiceAreaSchema} from '../service-area/schema';

export const SERVICE_AREA_DETAILS_FRAGMENT = `//groq
	_id,
	name,
	serviceReferences[]->{
		_id,
		name
	}
`;

export type ServiceAreaDetailsFragment = Pick<ServiceAreaSchema, '_id' | 'name'> & {
	serviceReferences?: ServiceDetailsFragment[];
};
