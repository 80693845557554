import clsx from 'clsx';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';

type Props = {
	imageDesktop: ImageWeb;
	imageMobile?: ImageWeb;
	format?: 'normal' | 'fullWidth' | 'cover';
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const Image = ({imageDesktop, imageMobile, format, spacingBottom, spacingTop}: Props) => {
	if (format === 'normal') {
		return (
			<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
				<div className={clsx(imageMobile && imageMobile.image && ['hidden', 'md:block'])}>
					<ImageBlock
						src={imageDesktop.image.src}
						alt={imageDesktop.altText ?? ''}
						width={imageDesktop.image.width}
						height={imageDesktop.image.height}
						defaultSizes="(max-width: 640px) 90vw, 1552px"
					/>
				</div>
				{imageMobile && imageMobile.image && (
					<div className="md:hidden">
						<ImageBlock
							src={imageMobile.image.src}
							alt={imageMobile.altText ?? ''}
							width={imageMobile.image.width}
							height={imageMobile.image.height}
							defaultSizes="(max-width: 640px) 90vw, 1552px"
						/>
					</div>
				)}
			</Container>
		);
	}

	if (format === 'fullWidth') {
		return (
			<Container width="full" spacingBottom={spacingBottom} spacingTop={spacingTop}>
				<div className={clsx(imageMobile && imageMobile.image && ['hidden', 'md:block'])}>
					<ImageBlock
						src={imageDesktop.image.src}
						alt={imageDesktop.altText ?? ''}
						width={imageDesktop.image.width}
						height={imageDesktop.image.height}
						defaultSizes="100vw"
					/>
				</div>
				{imageMobile && imageMobile.image && (
					<div className="md:hidden">
						<ImageBlock
							src={imageMobile.image.src}
							alt={imageMobile.altText ?? ''}
							width={imageMobile.image.width}
							height={imageMobile.image.height}
							defaultSizes="100vw"
						/>
					</div>
				)}
			</Container>
		);
	}

	if (format === 'cover') {
		return (
			<div className="relative h-dvh">
				<div
					className={clsx(
						'h-full',
						imageMobile && imageMobile.image && ['hidden', 'md:block'],
					)}
				>
					<ImageFill
						src={imageDesktop.image.src}
						alt={imageDesktop.altText ?? ''}
						width={imageDesktop.image.width}
						height={imageDesktop.image.height}
						hotspot={imageDesktop.image.hotspot}
						defaultSizes="100vw"
					/>
				</div>
				{imageMobile && imageMobile.image && (
					<div className="h-full md:hidden">
						<ImageFill
							src={imageMobile.image.src}
							alt={imageMobile.altText ?? ''}
							width={imageMobile.image.width}
							height={imageMobile.image.height}
							hotspot={imageDesktop.image.hotspot}
							defaultSizes="100vw"
						/>
					</div>
				)}
			</div>
		);
	}

	return null;
};
