import {PortableText} from '@/modules/foundation/sanity/portable-text';

import {
	TextFeatured,
	textFeaturedContentPortableTextOverrides,
} from '../../components/text-featured';

import type {TextFeaturedFragment} from './query';

type Props = {
	data: TextFeaturedFragment;
};

export const TextFeaturedContainer = ({data}: Props) => {
	const {content, layout, heading} = data ?? {};
	return (
		<TextFeatured
			spacingBottom={layout?.spacingBottom}
			spacingTop={layout?.spacingTop}
			heading={heading ?? ''}
		>
			{content && (
				<PortableText
					blocks={content}
					overrideClassNames={textFeaturedContentPortableTextOverrides}
					theme="dark"
				/>
			)}
		</TextFeatured>
	);
};
