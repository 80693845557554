import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerDetails} from '../../sanity/shared/types';

import {LogoSlider} from './LogoCarousel';
import {LogoGrid} from './LogoGrid';

type Props = {
	style: 'grid' | 'carousel';
	customers: KeyedArray<CustomerDetails>;
};

export const LogoListSelection = ({style, customers}: Props) => {
	return style === 'grid' ? (
		<LogoGrid customers={customers} />
	) : (
		<LogoSlider customers={customers} />
	);
};
