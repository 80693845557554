import type {CustomerTestimonialDetails} from '../shared/types';

import type {CustomerTestimonialSchema} from './schema';

export const CUSTOMER_TESTIMONIAL_IN_CARD_FRAGMENT = `//groq
	_id,
	name,
	quote,
	role,
	customerReference-> {
		name,
	},
`;

export type CustomerTestimonialInCardFragment = Pick<
	CustomerTestimonialSchema,
	'_id' | 'name' | 'quote' | 'role'
> & {
	name: string;
	quote: string;
	role: string;
	customerReference?: {
		name?: string;
	};
};

export function customerTestimonialInCardFragmentToCustomerTestimonialDetails(
	testimonialReference: CustomerTestimonialInCardFragment,
): CustomerTestimonialDetails {
	return {
		name: testimonialReference.name,
		quote: testimonialReference.quote,
		role: testimonialReference.role,
		customer: testimonialReference.customerReference?.name,
	};
}
