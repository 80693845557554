import type {FC} from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Person} from '../../shared/types';
import {PersonCard} from '../person-card';

interface Props {
	people: KeyedArray<Person>;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
}

const PersonList: FC<Props> = ({people, spacingBottom, spacingTop}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<ul
				className={clsx(
					'grid',
					'grid-cols-1',
					'sm:grid-cols-2',
					'lg:grid-cols-3',
					'gap-x-12',
					'gap-y-20',
				)}
			>
				{people.map((person) => {
					return (
						<li key={person._key}>
							<PersonCard person={person} />
						</li>
					);
				})}
			</ul>
		</Container>
	);
};

export default PersonList;
