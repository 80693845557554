import {groq} from 'next-sanity';

import {PORTABLE_TEXT_FRAGMENT} from '@/modules/foundation/sanity/portable-text/query';

import type {TextFeaturedSchema} from './schema';

export const TEXT_FEATURED_FRAGMENT = groq`
	...,
	content[] {
		${PORTABLE_TEXT_FRAGMENT}
	}
`;

export type TextFeaturedFragment = TextFeaturedSchema;
