import type {FC} from 'react';

import {ServiceAreaList} from '../../components/service-area-list';

import type {ServiceAreaListSelectionFragment} from './query';
import {serviceAreaListFromServiceAreaListSelectionSection} from './query';

type Props = {
	data: ServiceAreaListSelectionFragment;
};

export const ServiceAreaListSelectionContainer: FC<Props> = ({data}) => {
	const serviceAreas = serviceAreaListFromServiceAreaListSelectionSection(data);

	return (
		<ServiceAreaList
			serviceAreas={serviceAreas}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
