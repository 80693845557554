import type {FC} from 'react';

import type {ButtonProps} from '@/modules/foundation/components/button';
import {CallToAction} from '@/modules/foundation/components/call-to-action/CallToAction';
import {CallToActionGroup} from '@/modules/foundation/components/call-to-action-group/CallToActionGroup';

import {linkFragmentToHref} from '../../link/query';

import type {CallToActionLineFragment} from './query';

interface Props {
	value: CallToActionLineFragment;
	className?: string;
	color?: ButtonProps['intent'];
}

export const CallToActionLineContainer: FC<Props> = ({value, className, color}) => {
	return (
		<CallToActionGroup className={className}>
			{value.callToActions?.map((cta) => (
				<CallToAction
					key={cta._key}
					text={cta.text ?? ''}
					targetBlank={cta.targetBlank}
					color={color}
					href={linkFragmentToHref({
						type: cta.type,
						href: cta.href,
						_type: 'link',
						reference: cta.reference,
						targetBlank: cta.targetBlank,
					})}
					icon={cta.icon}
				/>
			))}
		</CallToActionGroup>
	);
};
