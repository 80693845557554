'use client';

import {type PropsWithChildren, useId} from 'react';
import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ProjectDetails} from '../../shared/types';
import {ProjectCard} from '../project-card';

import {NextButton, PrevButton, usePrevNextButtons} from './EmblaCarouselArrowButtons';

type Props = {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	projects: KeyedArray<ProjectDetails>;
	heading?: string;
};

const ProjectListCarousel = ({spacingBottom, spacingTop, projects, heading}: Props) => {
	const [emblaRef, emblaApi] = useEmblaCarousel({align: 'start', startIndex: 0});

	const {prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick} =
		usePrevNextButtons(emblaApi);

	const id = useId();

	return (
		<Container width="full" spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<div>
				<section className={clsx('mx-auto', 'relative', 'overflow-hidden')}>
					<div className={clsx('max-w-[calc(1552px+10vw)]', 'mx-auto', 'px-[5vw]')}>
						<div
							className={clsx(
								'mb-4',
								'md:mb-8',
								'flex',
								heading ? 'justify-between' : 'justify-end',
								'gap-4',
								'items-center',
							)}
						>
							{heading && (
								<Heading
									level={2}
									size="xsmall"
									className={clsx('md:text-4xl')}
									id={id}
								>
									{heading}
								</Heading>
							)}
							<div className={clsx('flex', 'gap-4', 'items-center')}>
								<PrevButton
									onClick={onPrevButtonClick}
									disabled={prevBtnDisabled}
								/>
								<NextButton
									onClick={onNextButtonClick}
									disabled={nextBtnDisabled}
								/>
							</div>
						</div>

						<div ref={emblaRef}>
							<ul
								className={clsx(
									'flex',
									'ml-[calc(48px*-1)]',
									'touch-pan-y',
									'touch-pinch-zoom',
								)}
								{...(heading ? {'aria-describedby': id} : {})}
							>
								{projects && projects.length && (
									<>
										{projects.map((project) => {
											if (!project) {
												return null;
											}

											return (
												<EmblaSlide key={project._key}>
													<ProjectCard project={project} />
												</EmblaSlide>
											);
										})}
									</>
								)}
							</ul>
						</div>
					</div>
				</section>
			</div>
		</Container>
	);
};

export default ProjectListCarousel;

const EmblaSlide = ({children}: PropsWithChildren) => {
	return (
		<li
			className={clsx(
				'flex-[0_0_100%]',
				'md:flex-[0_0_50%]',
				'min-w-0',
				'pl-12',
				'translate-3d',
			)}
		>
			{children}
		</li>
	);
};
