'use client';

import {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {FaPause, FaPlay} from 'react-icons/fa6';
import VimeoPlayer from '@vimeo/player';
import clsx from 'clsx';

import {usePrefersReducedMotion} from '../../hooks/usePrefersReducedMotion';

export type VideoType = {
	title: string;
	id: string;
};

type Props = {
	video?: VideoType;
	autoplay?: boolean;
	cover?: boolean;
};

export const VideoPlayer: FC<Props> = ({video, autoplay = false, cover}) => {
	const hasReducedMotionPreference = usePrefersReducedMotion();

	const [{height, width}, setDimensions] = useState({
		height: 0,
		width: 0,
	});
	// const [playing, setPlaying] = useState(autoplay ? true : false);
	const [isPlaying, setIsPlaying] = useState(autoplay);
	const [player, setPlayer] = useState<VimeoPlayer | null>(null);
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const videoContainerRef = useRef<HTMLDivElement>(null);

	const [parentWidth, setParentWidth] = useState(
		videoContainerRef.current?.parentElement?.scrollWidth ?? 0,
	);
	const [parentHeight, setParentHeight] = useState(
		videoContainerRef.current?.parentElement?.scrollHeight ?? 0,
	);

	useEffect(() => {
		if (!videoContainerRef.current) {
			return;
		}
		const onResize = () => {
			setParentWidth(videoContainerRef.current?.parentElement?.scrollWidth ?? 0);
			setParentHeight(videoContainerRef.current?.parentElement?.scrollHeight ?? 0);
		};

		onResize();
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [videoContainerRef, height, width, iframeRef]);

	const videoParams = new URLSearchParams({
		autoplay: autoplay && !hasReducedMotionPreference ? '1' : '0',
		loop: autoplay && !hasReducedMotionPreference ? '1' : '0',
		autopause: autoplay && !hasReducedMotionPreference ? '0' : '1',
		title: '0',
		byline: '0',
		portrait: '0',
		controls: cover ? '0' : '1',
		muted: autoplay ? '1' : '0',
		fullscreen: '1',
	});

	// Initialize Vimeo player
	useEffect(() => {
		if (iframeRef.current) {
			const player = new VimeoPlayer(iframeRef.current);
			setPlayer(player);

			// Set play/pause state based on player events
			player.on('play', () => setIsPlaying(true));
			player.on('pause', () => setIsPlaying(false));

			// Clean up on component unmount
			return () => {
				player.off('play');
				player.off('pause');
				player.destroy();
			};
		}
	}, []);

	// Update isPlaying if autoplay changes
	useEffect(() => {
		setIsPlaying(autoplay);
	}, [autoplay]);

	// Handle play/pause toggle
	const togglePlayPause = useCallback(() => {
		if (player) {
			player.getPaused().then((paused) => {
				if (paused) {
					player.play().then(() => setIsPlaying(true));
				} else {
					player.pause().then(() => setIsPlaying(false));
				}
			});
		}
	}, [player]);

	// Fetch video dimensions from Vimeo API
	useEffect(() => {
		if (!video?.id) {
			return;
		}

		const getVideoDimensions = async () => {
			try {
				const response = await fetch(
					`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${video.id}`,
				);
				if (!response.ok) {
					throw new Error('Could not fetch video dimensions');
				}

				const dimensions = await response.json();
				setDimensions(dimensions);
			} catch (error) {
				console.error(error);
				setDimensions({height: 0, width: 0});
			}
		};

		getVideoDimensions();
	}, [video?.id]);

	const aspectRatio = cover ? `${parentWidth}/${parentHeight}` : `${width}/${height}`;

	const style = {
		height: '100%',
		width: '100%',
	};
	const parentAspectRatio = parentWidth / parentHeight;
	const videoAspectRatio = width / height;
	if (videoAspectRatio > parentAspectRatio) {
		const widthPercentRelativeToHeight = (100 * width) / height;
		const widthPercent = (widthPercentRelativeToHeight * parentHeight) / parentWidth;
		style.width = `${Math.ceil(widthPercent)}%`;
	} else if (videoAspectRatio < parentAspectRatio) {
		const heightPercentRelativeToWidth = (100 * height) / width;
		const heightPercent = (heightPercentRelativeToWidth * parentWidth) / parentHeight;
		style.height = `${Math.ceil(heightPercent)}%`;
	}

	return (
		<div
			ref={videoContainerRef}
			className={clsx(
				'relative',
				'flex',
				'justify-center',
				'items-center',
				'overflow-hidden',
				'max-w-full',
				'max-h-full',
			)}
			style={{aspectRatio: aspectRatio}}
		>
			{video?.id && (
				<iframe
					name="vimeo-player"
					title={video.title}
					src={`https://player.vimeo.com/video/${video.id}?${videoParams.toString()}`}
					allow="autoplay; muted;"
					style={style}
					className={clsx('absolute', cover && 'pointer-events-none')}
					ref={iframeRef}
					{...(cover && {tabIndex: -1})}
				></iframe>
			)}

			{cover && (
				<>
					{autoplay ? (
						<ButtonAutoplay onClick={togglePlayPause} playing={isPlaying} />
					) : (
						<ButtonPlayPause onClick={togglePlayPause} playing={isPlaying} />
					)}
				</>
			)}
		</div>
	);
};

type ButtonAutoplayProps = {
	playing: boolean;
	onClick: () => void;
};

const ButtonPlayPause = ({playing, onClick}: ButtonAutoplayProps) => {
	return (
		<button
			className={clsx(
				'absolute',
				'h-full',
				'w-full',
				'flex',
				'items-center',
				'justify-center',
				'group',
				'text-primary',
			)}
			onClick={onClick}
			aria-label={playing ? 'Pause video' : 'Start video'}
		>
			{playing ? (
				<div
					className={clsx(
						'size-14',
						'md:size-32',
						'bg-beige-100',
						'group-hover:bg-beige-400',
						'rounded-full',
						'grid',
						'place-items-center',
						'group-focus-visible:ring-4',
						'group-focus-visible:ring-secondary',
						'text-xl',
						'md:text-4xl',
						'opacity-0',
						'group-hover:opacity-100',
						'group-focus-visible:opacity-100',
						'transition-all',
						'duration-300',
						'shadow-[0px_0px_8px_rgba(0,0,0,0.2)]',
					)}
				>
					<FaPause aria-hidden />
				</div>
			) : (
				<div
					className={clsx(
						'size-14',
						'md:size-32',
						'bg-beige-100',
						'group-hover:bg-beige-400',
						'rounded-full',
						'grid',
						'place-items-center',
						'group-focus-visible:ring-4',
						'group-focus-visible:ring-secondary',
						'text-xl',
						'md:text-4xl',
						'shadow-[0px_0px_8px_rgba(0,0,0,0.2)]',
						'transition-colors',
					)}
				>
					<FaPlay
						aria-hidden
						className={clsx('relative', 'left-[2px]', 'md:left-[5px]')}
					/>
				</div>
			)}
		</button>
	);
};

const ButtonAutoplay = ({playing, onClick}: ButtonAutoplayProps) => {
	return (
		<button
			className={clsx(
				'absolute',
				'h-full',
				'w-full',
				'flex',
				'items-end',
				'justify-start',
				'group',
				'text-primary',
			)}
			onClick={onClick}
			aria-label={playing ? 'Pause video' : 'Start video'}
		>
			{playing ? (
				<div
					className={clsx(
						'size-10',
						'md:size-14',
						'bg-beige-100',
						'rounded-full',
						'grid',
						'place-items-center',
						'group-focus-visible:ring-4',
						'group-focus-visible:ring-secondary',
						'text-lg',
						'md:text-xl',
						'opacity-0',
						'group-hover:opacity-100',
						'group-focus-visible:opacity-100',
						'transition-all',
						'duration-300',
						'mb-4',
						'ml-4',
						'md:mb-8',
						'md:ml-8',
					)}
				>
					<FaPause aria-hidden />
				</div>
			) : (
				<div
					className={clsx(
						'size-10',
						'md:size-14',
						'bg-beige-100',
						'rounded-full',
						'grid',
						'place-items-center',
						'group-focus-visible:ring-4',
						'group-focus-visible:ring-secondary',
						'text-lg',
						'md:text-xl',
						'mb-4',
						'ml-4',
						'md:mb-8',
						'md:ml-8',
					)}
				>
					<FaPlay aria-hidden className={clsx('relative', 'left-[2px]')} />
				</div>
			)}
		</button>
	);
};
