'use client';

import {type ReactNode, useRef} from 'react';
import clsx from 'clsx';
import {motion, useInView, useReducedMotion, useScroll, useTransform} from 'framer-motion';
import Link from 'next/link';

import {Button} from '@/modules/foundation/components/button';
import {ArrowRightIcon} from '@/modules/foundation/components/icons/arrow-right-icon';
import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Heading} from '@/modules/foundation/components/typography/heading';

type Props = {
	heading: string;
	descriptionDesktop?: ReactNode;
	imageDesktop?: ImageWeb;
	link?: {
		href: string;
		targetBlank: boolean;
	};
	theme?: 'light' | 'dark';
	textAlignment?: 'left' | 'right';
};

export const Desktop = ({
	heading,
	descriptionDesktop,
	imageDesktop,
	link,
	theme = 'dark',
	textAlignment = 'left',
}: Props) => {
	const ref = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const isInView = useInView(contentRef, {once: true, margin: '0px 0px -20% 0px'});

	const {scrollYProgress} = useScroll({
		target: ref,
		offset: ['start end', 'end start'],
	});

	const y = useTransform(scrollYProgress, [0, 1], ['15vh', '-15vh']);

	return (
		<section
			ref={ref}
			className={clsx(
				'relative',
				'h-screen',
				'isolate',
				'px-[5vw]',
				theme === 'dark' ? 'text-beige-100' : 'text-primary',
				theme === 'dark' ? 'bg-primary' : 'bg-beige-200',
				'flex',
				'items-center',
				'overflow-hidden',
			)}
		>
			<div className={clsx('grid', 'grid-cols-2', 'w-full', 'max-w-[1520px]', 'mx-auto')}>
				<motion.div
					ref={contentRef}
					initial={{opacity: 0, x: -100}}
					animate={isInView ? {opacity: 1, x: 0} : undefined}
					transition={{duration: useReducedMotion() ? 0 : 0.9}}
					className={clsx(
						'w-full',
						'max-w-[610px]',
						textAlignment === 'left' ? 'col-start-1' : 'col-start-2 justify-self-end',
					)}
				>
					<Heading
						level={2}
						className={clsx('mb-14', 'text-[clamp(40px,6vw,80px)]', 'leading-[1.1]')}
					>
						{heading}
					</Heading>
					{descriptionDesktop}
					<Button
						asChild
						className={clsx(
							theme === 'light' ? 'text-primary' : 'text-beige-100',
							'mt-12',
						)}
					>
						<Link href={link?.href ?? '#'} aria-label={`Les mer om ${heading}`}>
							Les mer
							<ArrowRightIcon width={15} />
						</Link>
					</Button>
				</motion.div>
			</div>

			{imageDesktop && (
				<div
					className={clsx(
						'[clip:_auto]',
						'[-webkit-clip-path:_inset(0)]',
						'h-full',
						'left-0',
						'absolute',
						'top-0',
						'w-full',
						'-z-10',
					)}
				>
					<motion.div
						className={clsx(
							'fixed',
							'top-[-15vh]',
							'left-[-15vw]',
							'h-[130vh]',
							'w-[130vw]',
						)}
						style={{translateY: y}}
					>
						<ImageFill
							src={imageDesktop.image.src}
							width={imageDesktop.image.width}
							height={imageDesktop.image.height}
							alt={imageDesktop.altText ?? ''}
							hotspot={imageDesktop.image.hotspot}
							defaultSizes="100vw"
						/>
					</motion.div>
				</div>
			)}
		</section>
	);
};
