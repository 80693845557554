import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ProjectDetails} from '../../shared/types';
import {DOCUMENT_TYPE_PROJECT} from '../constants';
import type {ProjectInCardFragment} from '../query';
import {PROJECT_IN_CARD_FRAGMENT, projectInCardFragmentToProjectDetails} from '../query';

import type {BLOCK_TYPE_PROJECT_LIST_ALL_CAROUSEL} from './constants';
import type {ProjectListAllCarouselSchema} from './schema';

export const PROJECT_LIST_ALL_CAROUSEL_FRAGMENT = groq`
	...,
	orderBy == "publishDateNewestFirst" => {
		"projects": *[_type == "${DOCUMENT_TYPE_PROJECT}"] | order(coalesce(overridePublishedAt, _createdAt) desc) {
			${PROJECT_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "publishDateOldestFirst" => {
		"projects": *[_type == "${DOCUMENT_TYPE_PROJECT}"] | order(coalesce(overridePublishedAt, _createdAt) asc) {
			${PROJECT_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleAtoZ" => {
		"projects": *[_type == "${DOCUMENT_TYPE_PROJECT}"] | order(lower(title) asc) {
			${PROJECT_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleZtoA" => {
		"projects": *[_type == "${DOCUMENT_TYPE_PROJECT}"] | order(lower(title) desc) {
			${PROJECT_IN_CARD_FRAGMENT}
		}
	},
	!defined(orderBy) => {
		"projects": *[_type == "${DOCUMENT_TYPE_PROJECT}"] | order(coalesce(overridePublishedAt, _createdAt) desc) {
			${PROJECT_IN_CARD_FRAGMENT}
		}
	}
`;

export type ProjectListAllCarouselFragment = ProjectListAllCarouselSchema & {
	_type: typeof BLOCK_TYPE_PROJECT_LIST_ALL_CAROUSEL;
	projects: ProjectInCardFragment[];
};

export const projectListCarouselFromProjectListAllSection = (
	section: ProjectListAllCarouselFragment,
): KeyedArray<ProjectDetails> => {
	const projects: KeyedArray<ProjectDetails> = [];

	if (!section.projects?.length) {
		return projects;
	}

	for (const projectInCard of section.projects) {
		if (projectInCard) {
			const project = projectInCardFragmentToProjectDetails(projectInCard);
			if (project) {
				projects.push({
					_key: projectInCard._id,
					...project,
				});
			}
		}
	}

	return projects;
};
