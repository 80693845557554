import {groq} from 'next-sanity';

import type {CustomerTestimonialInCardFragment} from '../customer-testimonial/query';
import {CUSTOMER_TESTIMONIAL_IN_CARD_FRAGMENT} from '../customer-testimonial/query';
import type {CustomerTestimonialDetails} from '../shared/types';

import type {BLOCK_TYPE_CUSTOMER_TESTIMONIAL_SELECTION} from './constants';
import type {CustomerTestimonialSelectionSchema} from './schema';

export const CUSTOMER_TESTIMONIAL_SELECTION_FRAGMENT = groq`
	...,
	testimonialReference-> {
		${CUSTOMER_TESTIMONIAL_IN_CARD_FRAGMENT}
	}
`;

export type CustomerTestimonialSelectionFragment = Omit<
	CustomerTestimonialSelectionSchema,
	'testimonialReference'
> & {
	_type: typeof BLOCK_TYPE_CUSTOMER_TESTIMONIAL_SELECTION;
	testimonialReference?: CustomerTestimonialInCardFragment | null;
};

export const customerTestimonialDetailsFromCustomerTestimonialSelectionFragment = (
	section: CustomerTestimonialSelectionFragment,
): CustomerTestimonialDetails => {
	return {
		name: section.testimonialReference?.name,
		quote: section.testimonialReference?.quote,
		role: section.testimonialReference?.role,
		customer: section.testimonialReference?.customerReference?.name,
	};
};
