import {LogoListSelection} from '../../components/logo-list-selection';

import {logoListFromLogoListSelectionSection, type LogoListSelectionFragment} from './query';

type Props = {
	data: LogoListSelectionFragment;
};

export const LogoListSelectionContainer = ({data}: Props) => {
	const customers = logoListFromLogoListSelectionSection(data);

	return <LogoListSelection style={data.listStyle ?? 'carousel'} customers={customers} />;
};
