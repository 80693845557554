import type {PropsWithChildren} from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';

type Props = PropsWithChildren & {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const VacancyList = ({children, spacingBottom, spacingTop}: Props) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<div className={clsx('grid', 'gap-12', 'md:gap-16')}>{children}</div>
		</Container>
	);
};
