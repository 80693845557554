import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';

import type {Person} from '../shared/types';

import type {PersonSchema} from './schema';

export const PERSON_IN_CARD_FRAGMENT = groq`
	_id,
	name,
	roles,
	mainImage,
	hoverImage,
	email,
	phone
`;

export type PersonInCardFragment = Pick<
	PersonSchema,
	'_id' | 'name' | 'roles' | 'mainImage' | 'hoverImage' | 'email' | 'phone'
>;

export const personFromPersonInCardFragment = (personInCard: PersonInCardFragment): Person => {
	return {
		name: personInCard?.name ?? '',
		mainImage: !personInCard?.mainImage?.asset
			? undefined
			: imageWebFromImageWebSchema(personInCard.mainImage, 5 / 7),
		hoverImage: !personInCard?.hoverImage?.asset
			? undefined
			: imageWebFromImageWebSchema(personInCard.hoverImage, 5 / 7),
		phone: personInCard?.phone,
		email: personInCard?.email,
		roles: personInCard?.roles,
	};
};
