import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';
import {twMerge} from 'tailwind-merge';

const button = cva(
	[
		'no-underline',
		'inline-flex',
		'items-center',
		'justify-center',
		'gap-2',
		'shrink-0',
		'text-base',
		'md:text-lg',
		'border-b',
		'pb-1',
		'transition-colors',
	],
	{
		variants: {
			intent: {
				dark: ['text-primary', 'hover:text-secondary-dark', 'border-secondary-dark'],
				light: ['text-beige-100', 'hover:text-secondary', 'border-secondary'],
			},
		},
		defaultVariants: {
			intent: 'dark',
		},
	},
);

export type ButtonProps = VariantProps<typeof button> &
	ComponentPropsWithoutRef<'button'> & {
		/**
		 * Change the default rendered element for the one passed as a child, merging their props and behavior.
		 * @default false
		 */
		asChild?: boolean;
	};

/**
 * Button used for interaction
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({children, intent = 'dark', className, asChild, ...rest}, ref) => {
		const Component = asChild ? Slot : 'button';

		return (
			<Component ref={ref} className={twMerge(button({intent, className}))} {...rest}>
				{children}
			</Component>
		);
	},
);

Button.displayName = 'Button';
