import type {FC} from 'react';
import Link from 'next/link';

import type {ButtonProps} from '@/modules/foundation/components/button';
import {Button} from '@/modules/foundation/components/button';

import {ArrowLeftIcon} from '../icons/arrow-left-icon';
import {ArrowRightIcon} from '../icons/arrow-right-icon';
import {PlusIcon} from '../icons/plus-icon';

export type CallToActionProps = {
	href: string;
	targetBlank?: boolean;
	text: string;
	color?: ButtonProps['intent'];
	icon?: 'arrowRight' | 'arrowLeft' | 'plus' | 'none';
};

export const CallToAction: FC<CallToActionProps> = ({href, targetBlank, text, color, icon}) => {
	let renderedIcon = null;

	switch (icon) {
		case 'arrowLeft':
			renderedIcon = <ArrowLeftIcon aria-hidden width={15} />;
			break;
		case 'arrowRight':
			renderedIcon = <ArrowRightIcon aria-hidden width={15} />;
			break;
		case 'plus':
			renderedIcon = <PlusIcon aria-hidden width={15} />;
			break;
		case 'none':
			renderedIcon = null;
			break;
	}

	return (
		<Button asChild intent={color}>
			<Link href={href} {...(targetBlank && {target: '_blank'})}>
				{icon === 'arrowLeft' && renderedIcon}
				{text}
				{icon !== 'arrowLeft' && renderedIcon}
			</Link>
		</Button>
	);
};
