import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import type {VideoType} from '@/modules/foundation/components/video';
import {VideoPlayer} from '@/modules/foundation/components/video';

type Props = {
	video: VideoType;
	autoplay: boolean;
	format: 'normal' | 'fullWidth' | 'cover';
	layout?: {
		spacingBottom?: ContainerSpacing;
		spacingTop?: ContainerSpacing;
	};
};

export const Video = ({video, format, autoplay, layout}: Props) => {
	if (format === 'normal') {
		return (
			<Container spacingBottom={layout?.spacingBottom} spacingTop={layout?.spacingTop}>
				<VideoPlayer video={video} autoplay={autoplay} />
			</Container>
		);
	}

	if (format === 'fullWidth') {
		return (
			<Container
				spacingBottom={layout?.spacingBottom}
				spacingTop={layout?.spacingTop}
				width="full"
			>
				<VideoPlayer video={video} autoplay={autoplay} />
			</Container>
		);
	}

	if (format === 'cover') {
		return (
			<Container
				spacingBottom={layout?.spacingBottom}
				spacingTop={layout?.spacingTop}
				width="full"
			>
				<div className="h-dvh">
					<VideoPlayer video={video} cover autoplay={autoplay} />
				</div>
			</Container>
		);
	}

	return null;
};
