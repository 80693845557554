import type {SVGProps} from 'react';

export const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 17 14"
		{...props}
	>
		<path
			fill="currentColor"
			d="M1.033 6.381a.875.875 0 0 0 0 1.238L6.6 13.187A.875.875 0 1 0 7.84 11.95L2.889 7l4.95-4.95A.875.875 0 1 0 6.6.813L1.033 6.38Zm15.604-.256H1.65v1.75h14.986v-1.75Z"
		/>
	</svg>
);
