import type {FC} from 'react';
import React from 'react';

import {ProjectList} from '../../components/project-list';

import {
	projectListFromProjectListSelectionSection,
	type ProjectListSelectionFragment,
} from './query';

type Props = {
	data: ProjectListSelectionFragment;
};

export const ProjectListSelectionContainer: FC<Props> = ({data}) => {
	const projects = projectListFromProjectListSelectionSection(data);

	return (
		<ProjectList
			projects={projects}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
