import type {SVGProps} from 'react';

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 14 14"
		{...props}
	>
		<rect width={13.25} height={1.75} x={0.652} y={6.125} fill="currentColor" rx={0.875} />
		<rect
			width={13.25}
			height={1.75}
			x={6.402}
			y={13.625}
			fill="currentColor"
			rx={0.875}
			transform="rotate(-90 6.402 13.625)"
		/>
	</svg>
);
